import React from 'react'

const ApiCallLoader = () => {
  return (
<div className="api-spinner">
  <span></span>
  <span></span>
  <span></span>
</div>  )
}

export default ApiCallLoader