import React, { useEffect, useState } from "react";
import personalData from "../../../constants/PersonalData";
import ApiCall from "../../../api/ApiCall";
import {
  bookAppointmentEndPoints,
  doctorEndPoints,
} from "../../../api/Endpoints";
import { toast } from "react-toastify";
import ErrorMessage from "../../../components/ErrorMessage";
import ApiCallLoader from "../../../components/Loaders/ApiCallLoader";
import ToasterMessages from "../../../utils/toasterMessage";
import { startApiCall } from "../../../global/globalFunctions";

const BookAppointment = () => {
  const [loader, setLoader] = useState(false);
  const [docLoader, setdocLoader] = useState(false);
  const [errorMessage, seterrorMessage] = useState("");
  const [contactUs, setcontactUs] = useState({
    name: "",
    phone: "",
    message: "",
  });
  const [allDoctor, setallDoctor] = useState(null);
  const [doctorId, setdoctorId] = useState("");

  const closeModal = () => {
    // setcontactUs({
    //   name: "",
    //   phone: "",
    //   message: "",
    // });
    // setdoctorId("");
    setLoader(false);
  };

  const handleGetAllDoctors = async () => {
    startApiCall(seterrorMessage, setdocLoader);
    const data = {
      pageLimit: 500,
      pageNumber: 1,
      filters: {
        isDeleted: false,
      },
    };
    const res = await ApiCall("post", doctorEndPoints.getDoctors, data);
    if (res?.success) {
      setallDoctor(res?.result?.data);
      setdocLoader(false);
    } else {
      seterrorMessage(res.error);
      setdocLoader(false);
    }
  };

  useEffect(() => {
    handleGetAllDoctors();
  }, []);

  const handleContactUs = async (e) => {
    e.preventDefault();
    startApiCall(seterrorMessage, setLoader);
    const payload = {
      name: contactUs?.name,
      phone: contactUs?.phone,
      message: contactUs?.message,
      type: "Book Appointment",
      doctorId,
    };
    const res = await ApiCall(
      "post",
      bookAppointmentEndPoints.bookAppointment,
      payload
    );
    if (res?.success) {
      toast.success(ToasterMessages.BOOKING);
      closeModal();
    } else {
      seterrorMessage(res.error);
      setLoader(false);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phone" && value.length > 10) return;
    setcontactUs({
      ...contactUs,
      [name]: value,
    });
  };
  return (
    <div className="wrapper">
      <section className="contact-layout1 pt-0 mt-30">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-panel d-flex flex-wrap">
                <form
                  className="contact-panel__form"
                  onSubmit={handleContactUs}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <h4 className="contact-panel__title">
                        Appointment Booking Page
                      </h4>

                      <p className="contact-panel__desc mb-10">
                        Please Fill in your contact details below form, and our
                        team will reach out to you soon!
                      </p>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <div className="form-group">
                        <i className="icon-news form-group-icon"></i>
                        <select
                          required
                          className="form-control"
                          value={doctorId}
                          onChange={(e) => setdoctorId(e.target.value)}
                        >
                          <option value="">----Choose Doctor---</option>
                          {docLoader && (
                            <option disabled>Loading Doctors...</option>
                          )}
                          {!docLoader && (
                            <>
                              {allDoctor
                                ?.filter(
                                  (item) =>
                                    item?._id !== "65b16a4e636b1e2b35942144" &&
                                    item?._id !== "65b16a4e636b1e2b35942143"
                                )
                                .map((item, index) => {
                                  return (
                                    <>
                                      {
                                        <option value={item?._id}>
                                          {index + 1}. {item?.name} (
                                          {item?.department})
                                        </option>
                                      }
                                    </>
                                  );
                                })}
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <i className="icon-user form-group-icon"></i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                          id="contact-name"
                          name="name"
                          required
                          value={contactUs?.name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-6">
                      <div className="form-group">
                        <i className="icon-phone form-group-icon"></i>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Your Phone"
                          id="phone"
                          name="phone"
                          required
                          value={contactUs?.phone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <i className="icon-alert form-group-icon"></i>
                        <textarea
                          className="form-control"
                          placeholder="Enter your Full Address Here"
                          id="message"
                          name="message"
                          value={contactUs?.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </div>
                      {errorMessage && (
                        <ErrorMessage errorMessage={errorMessage} />
                      )}

                      <button
                        type="submit"
                        disabled={loader}
                        className="btn btn__secondary btn__rounded btn__block btn__xhight mt-10"
                      >
                        <span>
                          {loader ? "Please wait" : "Book Appoinment"}
                        </span>
                        {loader && <ApiCallLoader />}
                        <i className="icon-arrow-right"></i>
                      </button>
                      <div className="contact-result"></div>
                    </div>
                  </div>
                </form>
                <div className="contact-panel__info d-flex flex-column justify-content-between bg-overlay bg-overlay-primary-gradient">
                  <div>
                    <h4
                      className="contact-panel__title color-white"
                      style={{ fontSize: "23px" }}
                    >
                      Booking Appointment
                    </h4>
                    <div className="badge pulse-text">🔴 Live now</div>
                    <p
                      className="contact-panel__desc font-weight-bold color-white mb-0"
                      style={{ marginTop: "10px" }}
                    >
                      Book your health appointments effortlessly with our
                      user-friendly platform !
                    </p>
                  </div>
                  <div>
                    <ul className="contact__list list-unstyled mb-30 mt-30">
                      <li>
                        <i className="icon-location"></i>
                        <span>Location: {personalData?.LOCALADDRESS}</span>
                      </li>
                      <li>
                        <i className="icon-clock"></i>
                        <span>{personalData?.TIMINGS}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookAppointment;
