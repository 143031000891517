import React from "react";

const ErrorMessage = ({ errorMessage }) => {
  return (
    <div className="d-flex justify-content-center mb-2 w-100">
      <i
        className="fa fa-exclamation-circle"
        style={{
          color: "red",
          marginRight: "4px",
          marginTop: "4px",
          fontSize: "20px",
        }}
      ></i>
      <p className="link-danger text-capitalize">{errorMessage}</p>
    </div>
  );
};

export default ErrorMessage;
