let host = window.location.href;
let isProduction = host?.includes("muktihealthcare") ? true : false;
console.log(
  isProduction
    ? "🚨🚨🚨🚨🚨🚨LIVE🚨🚨🚨🚨🚨🚨"
    : "🚧🚧🚧🚧🚧DEVELOPMENT🚧🚧🚧🚧🚧🚧"
);

// ********* BASE URL ********
export const BASE_URL = isProduction
  ? "https://api.muktihealthcare.in/api"
  : "http://localhost:8005/api";


  // export const BASE_URL = 'http://localhost:8000/api'
  // export const BASE_URL = 'https://api.muktihealthcare.in/api'



