const personalData = {
  LOCALADDRESS: "Kaliyaganj , 733129",
  ADDRESS: "Hospital Road , Kaliyaganj , Uttar Dinajpur , 733129, West Bengal.",
  TIMINGS: "Everyday : 8:00 am - 10:00 pm",
  EMAIL: "muktipharmacykaliyaganj@gmail.com",
  RECEPTION_PHONE: "7477539299",
  WHATSAPP_PHONE: "9134002237",
  WHATSAPPME: "https://wa.me/919134002237",
  FACEBOOK: "https://www.facebook.com/muktipharmacy?mibextid=ZbWKwL",
  INSTAGRAM:
    "https://www.instagram.com/muktipolyclinicdiagnostic?igshid=YzVkODRmOTdmMw%3D%3D",
  GOOGLE: "https://g.co/kgs/NeG64Q",
  YOUTUBE:"https://youtube.com/@MuktiPolyclinicDiagnostic?si=tN5E_cgX11WOv6Ko"
};
export default personalData;
