import React from "react";
import { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ApiCall from "./api/ApiCall";
// import AuthRoutes from "./all-routes/Authentication/AuthRoutes";
import { authenticateEndpoints } from "./api/Endpoints";
import { useDispatch } from "react-redux";
// import { actionCreators } from "./redux-setup";
import { isOwner } from "./global/globalFunctions";
import MainRoutes from "./Routes/MainRoutes";
import { ToastContainer } from "react-toastify";
import { PrivateRoutes } from "./Routes/PrivateRoutes";

import PageLoader from "./components/Loaders/PageLoader";
import BookAppointment from "./pages/Public/Appointment/BookAppointment";


const Header = lazy(() => import("./components/Header"));
const Home = lazy(() => import("./pages/Public/Home/Home"));
const Footer = lazy(() => import("./components/Footer"));
const About = lazy(() => import("./pages/Public/AboutUs/About"));
const ContactUs = lazy(() => import("./pages/Public/Contact/ContactUs"));
const HomeDelivery = lazy(() => import("./pages/Public/HomeDelivery/HomeDelivery"));
const ContactList = lazy(() => import("./pages/Public/Contact/ContactList"));
const OurDoctor = lazy(() => import("./pages/Public/Doctor/OurDoctor"));
const DoctorDetails = lazy(() => import("./pages/Public/Doctor/DoctorDetails"));
const DoctorTimeTable = lazy(() =>
  import("./pages/Public/Doctor/DoctorTimeTable")
);
const OurProducts = lazy(() => import("./pages/Public/Shop/OurProducts"));
const ProductDetails = lazy(() => import("./pages/Public/Shop/ProductDetails"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop"));
const Customer = lazy(() => import("./pages/Admin/Customer/Customer"));
const CustomerDetails = lazy(() =>
  import("./pages/Admin/Customer/CustomerDetails/CustomerDetails")
);
const Doctor = lazy(() => import("./pages/Admin/Doctor/Doctor"));
const Login = lazy(() => import("./pages/Admin/Login/Login"));
const OwnerProfile = lazy(() => import("./pages/Admin/Owner/OwnerProfile"));
const Staff = lazy(() => import("./pages/Admin/Staff/Staff"));
const StaffDetails = lazy(() =>
  import("./pages/Admin/Staff/StaffDetails/StaffDetails")
);
const DoctorShift = lazy(() => import("./pages/Admin/DoctorShift/DoctorShift"));
const Template = lazy(() => import("./pages/Admin/Template/Template"));
const AddShift = lazy(() =>
  import("./pages/Admin/DoctorShift/AddShift/AddShift")
);
const AdminApproval = lazy(() =>
  import("./pages/Admin/AdminApproval/AdminApproval")
);
const Pharmacy = lazy(() => import("./pages/Public/Pharmacy/Pharmacy"));
const WhatsAppButton = lazy(() => import("./components/WhatsAppButton"));
const OPDServicesDetails = lazy(() =>
  import("./pages/Public/Services/OPDServicesDetails")
);
const DiagnosticServiceDetails = lazy(() =>
  import("./pages/Public/Services/DiagnosticServiceDetails")
);

function App() {
  const refreshToken = localStorage.getItem("refreshTokenForTaskManager");
  const [isLoggedIn, setisLoggedIn] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  // const dispatch = useDispatch();

  // ******** AUTHENTICATE USER CHECK**********
  const handleAuthenticateUser = async () => {
    // console.log("***Logged in Success.Token Verified***");
    // const res = await ApiCall("get", authenticateEndpoints.verifyUser, {
    //   params: { token: refreshToken },
    // });
    // if (res?.success) {
    //   // dispatch(actionCreators.addAuthenticateUser(res.user));
    //   setisLoggedIn(true);
    //   setIsLoaded(true);
    // } else {
    //   setisLoggedIn(false);
    //   setIsLoaded(true);
    // }
  };

  // ******** AUTHENTICE USER API CALLED**********
  // useEffect(() => {
  //   if (refreshToken) {
  //     handleAuthenticateUser();
  //   } else {
  //     setisLoggedIn(false);
  //     setIsLoaded(true);
  //   }
  // }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <WhatsAppButton />
      <Header />
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path={MainRoutes.HOME} element={<Home />} />
          <Route path="*" element={<Home />} />
          <Route path={MainRoutes.ABOUT} element={<About />} />
          <Route path={MainRoutes.CONTACTS} element={<ContactUs />} />
          <Route path={MainRoutes.HOMEDELIVERY} element={<HomeDelivery />} />
          {/* <Route path={MainRoutes.SERVICES} element={<HomeDelivery />} /> */}
          <Route
            path={MainRoutes.DIAGNOSTICSERVICEDETAILS}
            element={<DiagnosticServiceDetails />}
          />
          <Route
            path={MainRoutes.OPDSERVICEDETAILS}
            element={<OPDServicesDetails />}
          />

          <Route path={MainRoutes.LOGIN} element={<Login />} />

          <Route path={MainRoutes.PHARMACY} element={<Pharmacy />} />
          <Route path={MainRoutes.DOCTORDETAILS} element={<DoctorDetails />} />
          <Route path={MainRoutes.DOCTORS} element={<OurDoctor />} />
          <Route path={MainRoutes.TIMETABLE} element={<DoctorTimeTable />} />
          <Route path={MainRoutes.PRODUCT} element={<OurProducts />} />
          <Route path={MainRoutes.BOOKAPPOINTMENT} element={<BookAppointment />} />
          <Route
            path={MainRoutes.PRODUCTDETAILS}
            element={<ProductDetails />}
          />
          {/* ******* MY PrivateRoutes ****** */}
          <Route element={<PrivateRoutes />}>
            <Route path={MainRoutes.CUSTOMER} element={<Customer />} />
            <Route path={MainRoutes.CONTACTLIST} element={<ContactList />} />
            <Route
              path={MainRoutes.CUSTOMERDETAILS}
              element={<CustomerDetails />}
            />
            <Route path={MainRoutes.STAFFDETAILS} element={<StaffDetails />} />
            <Route path={MainRoutes.DOCTOR} element={<Doctor />} />

            <Route path={MainRoutes.STAFF} element={<Staff />} />
            <Route path={MainRoutes.OWNERDETAILS} element={<OwnerProfile />} />
            <Route path={MainRoutes.SHIFTLIST} element={<DoctorShift />} />
            <Route
              path={MainRoutes.SHIFTAPPROVAL}
              element={isOwner() ? <AdminApproval /> : <Home />}
            />
            <Route path={MainRoutes.TEMPLATE} element={<Template />} />
            <Route path={MainRoutes.ADDSHIFT} element={<AddShift />} />
          </Route>
        </Routes>
      </Suspense>
      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={1500}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        limit={1}
      />
    </BrowserRouter>
  );
}

export default App;
