const customerPrefix = "customer";
const doctorPrefix = "doctor";
const managementPrefix = "management";
const templatePrefix = "template";
const contactUsPrefix = "contactus";



// ** CUSTOMER RELATED ENDPOINTS **
export const customerEndPoints = {
  addCustomer: customerPrefix + "/add-customer",
  addBulkCustomer: customerPrefix + "/add-bulk-customer",
  getCustomers: customerPrefix + "/get-customers",
  getCustomersDetails: customerPrefix + "/get-customer-by-id",
  editCustomer: customerPrefix + "/edit-customer",
};

// ** DOCTOR RELATED ENDPOINTS **
export const doctorEndPoints = {
  getDoctors: doctorPrefix + "/get-doctors",
  getDoctorDetails: doctorPrefix + "/get-doctor-by-id",
  addDoctor: doctorPrefix + "/add-doctor",
  getDoctorDepartment: doctorPrefix + "/get-departments",
  getDoctorTimeTableWeekly: doctorPrefix + "/get-time-table",
  editShift: doctorPrefix + "/edit-shift",
};

// ** AUTH RELATED ENDPOINTS **
export const AuthEndPoints = {
  adminLogin: managementPrefix + "/management-login",
  verifyUser: managementPrefix + "/verify-user",

};


// ** MANAGEMENT ENDPOINTS **
export const managementEndPoints = {
  editManagement: managementPrefix + "/edit-management",
  addManagement: managementPrefix + "/add-management",
  getAllManagement: managementPrefix + "/get-all-management",
  getManagementById: managementPrefix + "/get-management-by-id",
  approvalTemplateShift: managementPrefix + "/get-template-and-shift",
  addBulkCustomer: managementPrefix + "/csv-customer-details"
};

// ** SHIFT ENDPOINTS **
export const shiftEndPoints = {
  getShifts: doctorPrefix + "/get-shifts",
  editShifts: doctorPrefix + "/edit-shift",
  addshift: doctorPrefix + "/add-shift",

};

// ** TEMPLATE ENDPOINTS **
export const templateEndPoints = {
  getTemplates: templatePrefix + "/get-templates",
  changeTemplateStatus: templatePrefix + "/change-status",
  addTemplate: templatePrefix + "/add-template",
  deleteTemplate: templatePrefix + "/delete-template",
  checkMessageState: templatePrefix + "/check-running-message",
};

// ** SEND MESSAGE ENDPOINTS **
export const sendMessageEndPoints = {
  sendMessage: templatePrefix + "/send-wptext",
  sendMessageUser: templatePrefix + "/send-message-to-user",

};

// ** CONTACT US ENDPOINTS **
export const contactUsEndPoints = {
  addContactToUs: contactUsPrefix + "/add-contactus",
  getContactList: contactUsPrefix + "/get-contactus",
};

// ** BOOK APPOINMENT ENDPOINTS **
export const bookAppointmentEndPoints = {
  bookAppointment: contactUsPrefix + "/book-appointment",
  sendMsgAppointment: contactUsPrefix + "/send-msg-book-appointment"
};