const MainRoutes = {
  HOME: "/",
  ABOUT: "/about",
  CONTACTS: "/contact",
  CONTACTLIST: "/contact/list",
  BOOKAPPOINTMENT: "/appointment",
  HOMEDELIVERY: "/contact/delivery",
  SERVICES: "/service",
  DIAGNOSTICSERVICEDETAILS: "/service/diagnostic",
  OPDSERVICEDETAILS: "/service/opd",
  DOCTORS: "/doctor",
  DOCTORDETAILS: "/doctor/details",
  TIMETABLE: "/doctor/time-table",
  PRODUCT: "/product",
  PRODUCTDETAILS: "/product/details",
  LOGIN: "/admin/login",
  CUSTOMER: "/admin/customer",
  CUSTOMERDETAILS: "/admin/customer/:customerId",
  STAFFDETAILS: "/admin/staff/:staffId",
  DOCTOR: "/admin/doctor",
  DOCTORDETAILS: "/admin/doctor/:doctorId",
  OWNERDETAILS: "/admin/owner/details",
  STAFF: "/admin/staff",
  SHIFTLIST: "/admin/shift/list",
  SHIFTAPPROVAL: "/admin/shiftapproval",
  TEMPLATE: "/admin/template",
  ADDSHIFT: "/admin/addshift",
  PHARMACY: "/pharmacy",
};

export default MainRoutes;
