import MainRoutes from "../Routes/MainRoutes";
import ApiCall from "../api/ApiCall";
import { AuthEndPoints, doctorEndPoints } from "../api/Endpoints";
import security from "../constants/security";

//******* START API CALL ********/
export const startApiCall = (seterrorMessage, setLoader) => {
  {
    seterrorMessage !== null && seterrorMessage("");
  }
  setLoader(true);
  setTimeout(() => {
    setLoader(false);
  }, 50000);
};

//******* CONVER NUMBER RANGE INTO ARRAY ********/
export const handleNumberIntoArray = (start, end) => {
  // console.log("start", start,end)
  return Array.from({ length: end - start + 1 }, (_, index) => start + index);
};

//******* PAGINATION HEADER DATA********/
export const getPaginationHeaderData = (
  page,
  pageLimit,
  totalCount,
  dataLength,
  moduleType
) => {
  let start = (page - 1) * pageLimit + 1;
  let end = start + dataLength - 1;
  let overall = `${start}-${end} of ${totalCount} ${
    moduleType ? moduleType : ""
  }`;
  return overall;
};

//********* DATE & TIME FORMATER ***********/
export const dateAndTimeFormatter = (isDate) => {
  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return new Date(isDate).toLocaleDateString("en-IN", options);
};

//********* GET All DEPARTMENTS OF DOCTOR ***********/
export const getAllDepartmentsOfDoctor = async () => {
  let res = await ApiCall("post", doctorEndPoints.getDoctorDepartment, {
    pageLimit: 500,
    pageNumber: 1,
    filters: {},
  });

  if (res.success) {
    return res.result.data;
  }
};

//********* VERIFY USER ***********/
export const verifyCurrentUser = async () => {
  const res = await ApiCall("get", AuthEndPoints.verifyUser, {
    params: { token: localStorage.getItem("refreshTokenForPharmacy") },
  });
  if (res.success) {
    window.location.href = MainRoutes.OWNERDETAILS;
  } else {
    localStorage.clear();
    window.location.href = MainRoutes.LOGIN;
  }
};

//********* GET MANAGEMENTID ***********/
export const getManagementIdLocal = () => {
  let managementId = localStorage.getItem("managementId");
  return managementId;
};

//********* CHECK USER LOGGED IN ***********/
export const isUserLoggedIN = () => {
  let managementId = localStorage.getItem("managementId");
  let tokenId = localStorage.getItem("refreshTokenForPharmacy");

  return managementId && tokenId ? true : false;
};

//********* CHECK USER IS OWNER ***********/
export const isOwner = () => {
  let type = localStorage.getItem("loginType");
  return type===security.OWNERKEY ? true : false;
};
//********* CHECK USER IS HAVE EDITOR ACCESS ***********/
export const isUserAllAccess = () => {
  let access = localStorage.getItem("accessType");
  return access===security.ALLACCESS ? true : false;
};

//********* GET COLOR BY STATUS ***********/
export const getColorByStatus = (status) => {
  if (status === "approved") {
    return "rgb(36 202 38)";
  } else if (status === "pending") {
    return "orange";
  } else if (status === "rejected") {
    return "rgb(239 13 39)";
  }
};

//********* GET CONVERT TIME INTO 12 FORMAT ***********/
export const convertTo12HourFormat = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(":");

  // Convert hours to a number
  let hoursNum = parseInt(hours, 10);

  // Determine AM or PM
  const period = hoursNum >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hoursNum = hoursNum % 12 || 12;

  // Format the result
  const formattedTime = `${hoursNum
    .toString()
    .padStart(2, "0")}:${minutes} ${period}`;

  return formattedTime;
};
