const ToasterMessages = {
  ADDCUSTOMER: "Success! Customer added to the list! 🎊",
  UPDATECUSTOMER: "Success! Customer Updated! 🎊",
  DELETECUSTOMER: "Success! Customer Deleted! 🎊",
  ADDDOCTOR: "Success! Doctor added to the list! 🎊",
  LOGIN: "Login Successfully ! 🎊",
  UPDATEOWNER: "Success! Owner Updated! 🎊",
  ADDSTAFF: "Success! Staff added to the list! 🎊",
  UPDATESTAFF: "Success! Staff Updated! 🎊",
  DELETESTAFF: "Success! Staff Deleted! 🎊",
  UPDATESHIFT: "Success! Shift Updated! 🎊",
  DELETESHIFT: "Success! Shift Deleted! 🎊",
  ADDSHIFT: "Success! Shifts are added! 🎊",
  APPROVESHIFT: "Success! Shift Approved! 🎊",
  REJECTSHIFT: "Success! Shift Rejected! 🎊",
  APPROVETEMPLATE: "Success! Template Approved! 🎊",
  REJECTTEMPLATE: "Success! Template Rejected! 🎊",
  ADDTEMPLATE: "Success! Template added to the list! 🎊",
  SENDMESSAGE: "Success! All Messages are succesfully send! 🎊",
  AUTHENTICATE: "Login Required ⚠️",
  CONTACTUS: "Thankyou for contacting us,we'll get back to you as soon as possible!",
  HOMEDELIVERY: "Thankyou for your interest in home delivery,we'll get back to you as soon as possible!",
  BOOKING: "Appointment Booked Successfully,we'll get back to you as soon as possible!",
  MESSAGESTATE:"We're currently processing your previous message-sending requests. Please wait for sometimes.",
  SENDAPPOINTMENTMSG:"Message Successfully Send"
};

export default ToasterMessages;
